<template>
  <div class="side">

    <div v-if="hasError">
      <h4>Bir hata oluştu</h4>
      {{ errorMessage }}
    </div>
    <div v-else>

      <div v-if="isLoading" class="loading">
        <div class="spinner"></div>
        {{ t('loading.loading') }}
      </div>

      <div v-else>

        <p>{{ t('parentCategory') }}
          <b>{{ state.parent_name }}</b>
        </p>

        <div class="input-wrap">
          <label for="name">{{ t('subCategoryName') }} <span class="requiredStar">*</span></label>
          <input v-model="state.name" type="text" id="name" class="form-control"/>
          <div v-if="hasNameError" class="error-msg">{{ t('enterCategoryName') }}</div>
        </div>

        <div class="input-wrap">
          <TagsInput v-model="state.shopify_collection_id" :items="collections" :label="t('categoryCollections')"/>
        </div>

        <div>
          <div class="input-wrap">
            <MySwitch v-model="state.is_active" :label="t('isCategoryStatusActive')"/>
          </div>
        </div>

        <div class="btnSet right">

          <router-link :to="{name: 'Index.Page'}" class="btn btn-danger">
            {{ t('cancel') }}
          </router-link>

          <a @click="onSubmit" :disabled="isFormInvalid" class="btn btn-primary">
            {{ submitting ? t('adding') : t('add') }}
          </a>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import TagsInput from '../TagsInput'
import MySwitch from '../MySwitch'
import useGlobal from '../../composables/useGlobal'
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, reactive, ref } from 'vue'

export default {

  components: {
    TagsInput,
    MySwitch,
  },

  setup() {

    const {

      successNotify,
      errorNotify,

      emitRefreshCategories,

      restApi,

      t,

    } = useGlobal()

    const route = useRoute()
    const router = useRouter()

    const hasError = ref(false)
    const errorMessage = ref('')
    const isLoading = ref(false)

    const submitting = ref(false)

    const state = reactive({
      parent_id: '',
      parent_name: '',
      name: '',
      is_active: true,
      image: {
        name: '',
        data: '',
        url: '',
        has_changed: false,
      },
      shopify_collection_id: [],
    })

    const rules = {
      name: { required },
    }

    const collections = ref([])

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const hasNameError = computed(() => {
      return $v.value.name.$error
    })

    const isFormInvalid = computed(() => {
      return $v.value.$invalid || submitting.value
    })

    const onSubmit = () => {

      submitting.value = true

      restApi.addSubCategorySubmit(state.parent_id, state.name, state.is_active, state.shopify_collection_id).
        then(() => {

          successNotify(t('categoryCreated'), t('categoryAdded'))

          emitRefreshCategories()

          router.replace({
            name: 'Index.Page',
          })

        }).
        catch(error => {

          submitting.value = false

          errorNotify(t('categoryNotCreated'), t('categoryCreatingError') + error.message)

        })

    }

    onMounted(() => {

      isLoading.value = true
      restApi.addSubCategoryShow(route.params.id).then(addSubShowResponse => {

        state.parent_id = addSubShowResponse.getCategoryId()
        state.parent_name = addSubShowResponse.getCategoryName()

        restApi.fetchCollections().then(response => {
          collections.value = response.getItems()
          isLoading.value = false
        }).catch(error => {
          hasError.value = true
          isLoading.value = false
          errorMessage.value = error.toString()
        })
      })

    })

    return {

      t,

      collections,

      hasError,
      errorMessage,
      isLoading,
      submitting,

      state,

      hasNameError,
      isFormInvalid,

      onSubmit,

    }
  },

}
</script>

<style scoped>

.requiredStar {
  color: red;
}

[disabled="true"] {
  pointer-events: none;
  color: #fff;
  background-color: lightgray;
  border: 1px solid lightgray;
}

[disabled="false"] {
  color: #fff;
  cursor: pointer;
}

</style>
